<!--图片上傳， 1. 获取s3存储路径-->
<template>
  <div class="viewImgBox">
    <template v-if="picUrl">
      <template v-if="!isPublic">
        <el-button type="primary" :text="true" @click="showUrl(picUrl)" :loading="loading" icon="el-icon-picture">
          <span class="viewSpan">{{ $t('g.view') }}</span>
        </el-button>
        <el-image :src="currentPic" fit="cover" ref="businessLicenseImgRef" :preview-src-list="[currentPic]" />

      </template>
    </template>

    <template v-else>
      <div class="noData">{{ $t('g.none') }}</div>
    </template>

    <el-dialog v-model="dialogVisible" :close-on-click-modal="false" width="80vw">
      <el-scrollbar height="80vh" v-loading="loading">
        <vue-office-pdf :src="pdfUrl" @rendered="renderedHandler" @error="errorHandler" />
      </el-scrollbar>
    </el-dialog>
    <!--  pdf查看  -->
  </div>
</template>
<script setup>
import { defineProps, ref, nextTick } from 'vue'
import { showImageUrl } from "@/api/global";
//引入VueOfficePdf组件
import VueOfficePdf from '@vue-office/pdf'
const dialogVisible = ref(false)

// pdf加载完成
const renderedHandler = () => {
  loading.value = false
}
const errorHandler = () => {
  loading.value = false
}

const props = defineProps({
  // 获取s3链接的图片路径
  picUrl: '',
  isPublic: {
    type: Boolean,
    default: false
  }
})

const loading = ref(false)
const currentPic = ref('')
const pdfUrl = ref('')
const currentPdfUrl = ref('')

// 获取图片地址
const businessLicenseImgRef = ref({})
const showUrl = async (name) => {
  loading.value = true
  let { data } = await showImageUrl(name)
  currentPic.value = data
  if (await isPdf(data)) {
    pdfUrl.value = data
    setTimeout(() => {
      dialogVisible.value = true
    }, 1000)
  } else {
    businessLicenseImgRef.value.$el.querySelector('img').click()
    loading.value = false
  }
}

// 是否pdf
const isPdf = async (url) => {
  // 创建一个URL对象
  const urlObj = new URL(url);

  // 获取文件名
  const filename = urlObj.pathname.split('/').pop();

  // 获取文件扩展名
  const fileExtension = filename.split('.').pop().toLowerCase();

  // 检查文件扩展名是否为pdf
  if (fileExtension === 'pdf') {
    return true;
  } else {
    return false;
  }
}
</script>

<style scoped lang="scss">
.viewImgBox {
  ::v-deep .el-button [class*=el-icon]+span {
    margin: 0;
  }

  width: 50px;
  display: flex;
  justify-content: center;

  .el-button,
  .el-button.is-text:not(.is-disabled):hover,
  .el-button.is-text:not(.is-disabled):focus {
    background: #e1f5f4 !important;
  }

  .noData {
    width: 30px;
    color: #c2c2c2;
    line-height: 30px;
    font-size: 14px;
    font-weight: normal;
  }

  position: relative;
  height: 30px;
  display: flex;

  ::v-deep .el-image {
    background: none !important;
    width: 60px;

    .el-image__inner {
      opacity: 0 !important;
    }

    .is-loading {
      background: none !important;
    }
  }


  .el-button {
    z-index: 2;
    position: absolute;
    top: -1px;
    left: 0;
    width: 60px;
  }
}
</style>
