
export default {
    path: "/systemNews",
    name: "systemNews",
    redirect: "/systemNews/list",
    // meta: { title: '系统消息' },
    component: () => import("@/views/systemNews"),
    children: [
        // 消息列表
        {
            path: "list",
            name: "systemNewsList",
            component: () => import("@/views/systemNews/list"),
        },
    ]
}

