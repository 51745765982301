
export default {
    path: "/qtkjcp",
    name: "qtkjcp",
    redirect: "/qtkjcp/progressList",
    // meta: { title: '其他科技產品' },
    component: () => import("@/views/serve/qtkjcp"),
    children: [
        // 同意须知
        {
            path: "agreen",
            name: "qtkjcpAgreen",
            component: () => import("@/views/serve/qtkjcp/agreen"),
            // meta: { title: '服務協議' }
        },
        // 注册步骤
        {
            path: "progressForm",
            name: "qtkjcpProgressForm",
            component: () => import("@/views/serve/qtkjcp/progressForm"),
        },
        // 进度列表
        {
            path: "progressList",
            name: "qtkjcpProgressList",
            component: () => import("@/views/serve/qtkjcp/progressList"),
        },
    ]
}

