
export default {
  path: "/information",
  name: "information",
  component: () => import("@/views/information"),
  // meta: { title: '個人資訊' },
  children: [
    // 個人資訊
    {
      name: "informationList",
      path: "informationList",
      component: () => import("@/views/information/list"),
    },
  ],
};
