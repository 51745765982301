// 回到顶部
import router from "@/router/index";

export const toTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

// 锚点跳转至表单
export function toForm() {
    const targetElement = document.getElementById('formBox');
    if (targetElement) {
        targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'nearest'
        });
    }
}

// 锚点跳转至聯絡我們
export function toContactUs() {
    const targetElement = document.getElementById('llwmFocus');
    if (targetElement) {
        targetElement.scrollIntoView({
            behavior: 'smooth',
            inline: 'nearest'
        });
    }
}

// 锚点跳转至查看案例
export function toCaseView() {
    const targetElement = document.getElementById('ckalFocus');
    if (targetElement) {
        targetElement.scrollIntoView({
            behavior: 'smooth',
            inline: 'nearest'
        });
    }
}

export function toPath(path) {
    router.push(path)
    // toTop()
}

// 获取当前时间周
export function getWeek(time) {
    const date = new Date(time)
    let weekArr = ['日', '一', '二', '三', '四', '五', '六']
    let week = date.getDay()
    return weekArr[week]
}

// 返回上一页
export function goBack() {
    router.back()
}

// 文件名加时间戳
export function fileNameValidate(fileName) {
    const fileNameArray = fileName.split(".");
    let validateName = fileNameArray.slice(0, fileNameArray.length - 1).join(".");
    let time = (new Date()).getTime()
    validateName += (time + '.' + fileNameArray[fileNameArray.length - 1])
    return validateName
}


/*---------------------------------------------------表单校验---------------------------------------*/
// 房屋編碼
export function houseCodeValidator (rule, value, callback) {
    if(!value) callback(new Error('請输入房屋编码'))
    const reg = /^\d{25}$/
    if (reg.test(value)) {
        callback()
    } else {
        callback(new Error('房屋编码格式不正确'))
    }
}

//所有手機號碼(香港&大陆)
export function allPhoneValidator(rule, value, callback) {
    if (!value) callback(new Error('請輸入電話號碼'))
    const reg1 = /^1\d{10}$/
    const reg2 = /^([123456789])\d{7}$/
    if (reg1.test(value) || reg2.test(value)) {
        callback()
    } else {
        callback(new Error('手機號碼格式不正確,請輸入正確手號碼'))
    }
}

// 内地手機號校验
export function mlPhoneValidator(rule, value, callback) {
    if (!value) callback(new Error('請輸入内地手機號'))
    const reg = /^1[345789]\d{9}$/
    if (reg.test(value)) {
        callback()
    } else {
        callback(new Error('内地手機號碼格式不正確,請輸入正確手號碼'))
    }
}

// 香港手机号校验
export function hkPhoneValidator(rule, value, callback) {
    if (!value) callback(new Error('請輸入香港手機號'))
    const reg = /^([5|6|9])\d{7}$/
    if (reg.test(value)) {
        callback()
    } else {
        callback(new Error('香港手機號碼格式不正確,請輸入正確手號碼'))
    }
}

// 邮箱校验
export function emailValidator(rule, value, callback) {
    if (!value) callback(new Error('請輸入電郵地址'))
    const reg = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/
    if (reg.test(value)) {
        callback()
    } else {
        callback(new Error('電郵格式不正確,請輸入正確電郵地址'))
    }
}


// 身份证号码校验
export function identityCardValidator(rule, value, callback) {
    if (!value) callback(new Error('請輸入身份證號碼'))
    // 大陆
    const reg1 = /^[1-9]\d{5}(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[0-1])\d{3}([0-9]|X)$/
    // 香港
    const reg2 = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])[0-9]([0-9]|X)$/
    if (reg1.test(value) || reg2.test(value)) {
        callback()
    } else {
        callback(new Error('身份證號碼格式不正確'))
    }
}

/*---------------------------------------------------表单校验- end--------------------------------------*/

// 获取path
export function getPath(url) {
    if (url) {
        const pathArr = url.split('/')
        pathArr.splice(pathArr.length - 1, 1)
        return pathArr.join('/') + '/'
    }
}

// 获取路劲下的文件名称
export function getPathName(url) {
    if (url) {
        const filename = url.split('/').pop()
        return filename.split('.')[0]
    }
}

// 深拷貝
export function cloneDeep (obj = {}) {            //变量先置空
    let newobj = null;

    //判断是否需要继续进行递归
    if (typeof (obj) == 'object' && obj !== null) {
        newobj = obj instanceof Array ? [] : {};                //进行下一层递归克隆
        for (var i in obj) {
            newobj[i] = cloneDeep(obj[i])
        }                //如果不是对象直接赋值
    } else newobj = obj;
    return newobj;
}

// 通过code获取地址名称 (第一级：市 第二级：区)
import city from '@/utils/gdCity'
export function cityName (cityCode=[]) {
    if(cityCode && cityCode.length === 2){
        let cityLevel1 = city.find(item => item.code === cityCode[0]) || {name: cityCode[0]}
        let cityLevel2 = cityLevel1.children?.find(item => item.code === cityCode[1]) || {name: cityCode[1]}
        return cityLevel1.name + cityLevel2.name
    }
    return ''
}




