<script setup>
import {onMounted, reactive, ref, defineProps, watch} from 'vue'
import {getList} from "@/api/serve";
import {useRouter} from "vue-router";
const router = useRouter()

// 相关服务列表
const tableList = ref([])

onMounted( () => {
  getServeList()
})

// prop
const prop = defineProps({
  // 相关服务排除当前id
  currentId: {
    type: String,
    default: '1'
  }
})

// 监听路由变化
// watch(
//     () => prop.currentId,
//     (nv, ov) => {
//       getServeList()
//     }
// )

// const currentId
const getServeList = async () => {
  try {
    let {data} = await getList({current: 1, size: 4})
    if(prop.currentId){
      tableList.value = data.list.filter(item => {
        return item.id !== Number(prop.currentId)
      }).slice(0,3)
    }else{
      tableList.value = data.list.slice(0,3)
    }
  }
  catch (e) {}
}

// 跳转服务详情
const toServeInfo = (id) => {
  router.push('/serveInfo?id='+id)
}
</script>

<template>
<!--相关服务-->
  <div class="relatedService">
    <div class="display-5 text-center mt-5 mb-5 serveTitle bold700">{{ $t('g.relatedService') }}</div>
    <div class="serveBox row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-3 g-4">
      <div class="col" v-for="(item, index) in tableList" :key="index" @click="toServeInfo(item.id)">
        <div class="item p-4">
          <div class="imgBox">
            <el-image :src="item.banner" fit="cover" />
          </div>
          <div class="typeBox">
            <span class="type ms-2" v-for="(item, index) in item.tags" :key="index">{{ item }}</span>
          </div>
          <div class="h2 title overflow">{{ item.name }}</div>
          <div class="user">{{ item.principal }}</div>
          <div class="info">
            <div class="left">
              <i class="iconfont icon-qian"></i> ${{ item.price }}
            </div>
            <div class="right">
              <i class="iconfont icon-shijian"></i> {{ item.estimatedTime }}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="scss">
.relatedService{
  .type {
    border-radius: 5px;
    padding: 5px 10px;
    background: rgba(70, 128, 117, 0.20);
    font-size: 14px;
  }
  .serveBox {

    .item {
      //margin-bottom: 30px;
      border-radius: 40px;
      border: 1px solid rgba(106, 205, 198, 0.40);
      cursor: pointer;
      font-size: 18px;

      ::v-deep .imgBox {
        width: 100%;
        overflow: hidden;
        margin-bottom: 12px;
        border-radius: 20px;
        padding-top: 100%;
        background: gray;
        position: relative;
        .el-image{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          img {
            transition: 0.5s;
            transform: scale(1);
            &:hover{
              transform: scale(1.3);
            }
          }
        }
      }

      .typeBox {
        display: flex;
        justify-content: end;
        height: 31px;
        overflow: hidden;
      }

      .title {
        font-weight: 700;
        margin-top: 10px;
        -webkit-line-clamp: 1;
      }

      .user {
        color: #8A90A2;
        margin-bottom: 10px;
      }

      .info {
        display: flex;
        justify-content: space-between;
        color: #468075;
        font-weight: 700;
      }
    }
  }
}
</style>
