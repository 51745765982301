<script setup>
import syButton from '@/components/syButton'
import { reactive, computed, ref } from 'vue'
import { ElMessage } from 'element-plus'
// api
import { getTypeChildren, getTypeList, addContact } from "@/api/global";

//引用i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const props = {
  lazy: true,
  async lazyLoad(node, resolve) {
    const { level } = node
    try {
      if (level > 0) {
        // 子级
        let parentId = node.data.value
        let { data } = await getTypeChildren(parentId)
        let nodes = data ? data.map(item => {
          return {
            label: item.name,
            value: item.id,
            leaf: item.isLeaf === '1'
          }
        }) : []
        resolve(nodes)
      } else {
        // 第一级
        let { data } = await getTypeList({ current: 1, size: 999 })
        let nodes = data ? data.list.map(item => {
          return {
            label: item.name,
            value: item.id,
            leaf: item.isLeaf === '1'
          }
        }) : []
        resolve(nodes)
      }
    }
    catch (e) { }
  }
}

// 提交表单
const loading = ref(false)
const formData = reactive({ subscribe: false, labelId: [], member: 0 })
const contactFormRef = ref()
const handleFinish = () => {
  contactFormRef.value.validate(async (v, invalidFields) => {
    if (v) {
      loading.value = true
      let apiData = { ...formData }
      apiData.subscribe = formData.subscribe ? '1' : '0'
      let lethen = formData.labelId.length
      apiData.labelId = lethen ? formData.labelId[lethen - 1] : ''
      try {
        let { messageCode } = await addContact(apiData)
        contactFormRef.value.resetFields();
        ElMessage.success(t(`langCode.${messageCode}`))
      }
      catch (e) { }
      loading.value = false
    }else{
      // 提示校验错误信息
      for (let i in invalidFields) {
        ElMessage.warning(invalidFields[i][0].message)
        return
      }
    }
  })
};

let isSelecting = ref(false);
const isDefaultStatus = computed(() => !(formData?.labelId?.length || isSelecting.value));
const selectVisibleChange = (e) => {
  isSelecting.value = e;
}

// rules
import {emailValidator} from "@/utils/global";
const rules = ref({
  lastName: [{required: true, message: t('g.input') + t('conUs.lastName'), trigger: 'blur'}],
  firstName: [{required: true, message: t('g.input') + t('conUs.firstName'), trigger: 'blur'}],
  email: [{required: true, validator: emailValidator, trigger: 'blur'}],
  phone: [{required: true, message: t('g.input') + 'BR' + t('conUs.phone'), trigger: 'blur'}],
  labelId: [{required: true, message: t('g.select') + 'BR' + t('conUs.labelId'), trigger: 'change'}],
})
</script>

<template>
  <div class="green-bg">
    <div class="llwm container">
      <div id="llwmFocus"></div>
      <div class="pt-5 pb-5 d-flex align-items-center">
        <div class="col-12 col-lg-6 p-4 d-none d-lg-block">
          <div class="imgBox me-4 ms-4">
            <img src="@/assets/images/home/llwm.png"/>
          </div>
          <div class="companyInfoBox p-3 lh-lg">
            <h4 class="w100">{{ $t('serve.HKHO') }}</h4>
            <div class="row w100">
              <div class="col-12 col-xl-6 d-flex align-items-center"><img src="@/assets/images/global/phone-b.png" alt="">+852 2491 5908</div>
              <div class="col-12 col-xl-6 d-flex align-items-center"><img src="@/assets/images/global/email-b.png" alt="">info@baculturemedia.com</div>
            </div>
            <div class="d-flex align-items-center w100">
              <img src="@/assets/images/global/location-b.png" alt="">{{ $t('serve.hkAddress') }}
            </div>
          </div>
          <div class="companyInfoBox p-3 lh-lg mt-3">
            <h4 class="w100">{{ $t('serve.SZBranch') }}</h4>
            <div class="row w100">
              <div class="col-12 col-xl-6 d-flex align-items-center"><img src="@/assets/images/global/phone-b.png" alt="">+86 186 7647 4566</div>
              <div class="col-12 col-xl-6 d-flex align-items-center"><img src="@/assets/images/global/email-b.png" alt="">info@baculturemedia.com</div>
            </div>
            <div class="d-flex align-items-center w100">
              <img src="@/assets/images/global/location-b.png" alt="">{{ $t('serve.szAddress') }}
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6 p-4 p-xl-5 formBox">
          <div class="h1 bold700 mb-4">{{ $t('g.contact') }}</div>
          <el-form :model="formData" ref="contactFormRef" :show-message="false" v-loading="loading" :rules="rules">
            <el-row :gutter="10">
              <el-col :span="12">
                <el-form-item required prop="lastName">
                  <div class="form-floating w100">
                    <input type="text" v-model="formData.lastName" class="form-control" :placeholder="`${$t('g.lName')}*`" />
                    <label>{{ $t('g.lName') }}*</label>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item required prop="firstName">
                  <div class="form-floating w100">
                    <input type="text" v-model="formData.firstName" class="form-control" :placeholder="`${$t('g.fName')}*`" />
                    <label>{{ $t('g.fName') }}*</label>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item required prop="email">
              <div class="form-floating w100">
                <input type="text" v-model="formData.email" class="form-control" placeholder=" " />
                <label>{{ $t('g.email') }}*</label>
              </div>
            </el-form-item>
            <el-form-item required prop="phone">
              <div class="form-floating w100">
                <input type="text" v-model="formData.phone" class="form-control" placeholder=" " />
                <label>{{ $t('conUs.phone') }}*</label>
              </div>
            </el-form-item>
            <el-form-item required prop="labelId">
              <div class="sy-cascader">
                <el-cascader placeholder=" " clearable v-model="formData.labelId" :props="props" change-on-select="true"
                             @visible-change="selectVisibleChange" />
                <label :class="{ move: isDefaultStatus }">{{ $t('g.profession') }}*</label>
              </div>
            </el-form-item>
            <el-form-item required prop="content">
              <div class="form-floating col-lg-12 w100">
                <textarea class="form-control" v-model="formData.content" :placeholder="$t('g.detailContent') + '*'"></textarea>
                <label class="floatingTextarea"><span class="remark">{{ $t('g.detailContent') }}*</span> </label>
              </div>
            </el-form-item>
            <el-form-item prop="subscribe">
              <el-checkbox v-model="formData.subscribe"><span class="font-cor bold700">{{ $t('g.subscribeNews') }}</span></el-checkbox>
            </el-form-item>
            <el-form-item prop="member">
              <span class="bold700 me-3">{{ $t('tips.isVip') }}</span>
              <el-radio-group v-model="formData.member">
                <el-radio :label="1">{{ $t('g.yes') }}</el-radio>
                <el-radio :label="0">{{ $t('g.no') }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item>
              <syButton class="contactBtn w100 m-0" @click="handleFinish">
                {{ $t('g.submit') }}<i class="iconfont icon-fangxiang2-youbian"></i>
              </syButton>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
::v-deep .form-control {
  border: 0px !important;
}

::v-deep .el-input__inner {
  transform: translateY(-5px);
  font-size: 16px;
}

.left {
  position: relative;
}

::v-deep .el-input__wrapper {
  box-shadow: none !important;
  border: 0;
}


.font-cor {
  color: #01374F;
}

::v-deep .move {
  font-size: 17px !important;
  top: 10px;
  color: #01374F !important;

}

::v-deep .sy-cascader label {
  font-size: 14px;
  color: rgba(var(--bs-body-color-rgb), .65);

  transition: 0.2s;
  padding: 0.8rem 0.5rem;
}

// .el-form .el-input__wrapper
::v-deep .el-input__wrapper {
  background: #e1f5f4 !important;

}

.llwm {
  position: relative;
  .companyInfoBox{
    background: #fff;
    border-radius: 20px;
    img{
      width: 24px;
      margin-right: 10px;
    }
  }

  #llwmFocus {
    width: 1px;
    height: 1px;
    position: absolute;
    top: -100px;
    left: 0;
  }

  .form-floating textarea {
    height: 120px;
    resize: none;
  }

  .formBox {
    border-radius: 40px;
    box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.10);
    background: #c4e5e2;
    height: 100%;
  }
}
@media only screen and (max-width: 992px){
  .llwm {
    .formBox{
      border-radius: 15px;
    }
  }
}

</style>
