
export default {
    path: "/ndflgw",
    name: "ndflgw",
    redirect: "/ndflgw/progressList",
    component: () => import("@/views/serve/ndflgw"),
    children: [
        // 同意须知
        {
            path: "agreen",
            name: "ndflgwAgreen",
            component: () => import("@/views/serve/ndflgw/agreen"),
            // meta: { title: '服務協議' }
        },
        // 注册步骤
        {
            path: "progressForm",
            name: "ndflgwProgressForm",
            component: () => import("@/views/serve/ndflgw/progressForm"),
        },
        // 进度列表
        {
            path: "progressList",
            name: "ndflgwProgressList",
            component: () => import("@/views/serve/ndflgw/progressList"),
        },
    ]
}

