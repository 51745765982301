import { createI18n } from "vue-i18n";
import EN from "./en";
import ZH from "./zh";
import FT from "./ft";

const messages = {
    zh: {
        ...ZH,
    },
    en: {
        ...EN,
    },
    ft: {
        ...FT,
    }
};
const lang = localStorage.getItem('LANG')
const i18n = createI18n({
    locale: lang || "ft", // 设置当前语言類型
    legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
    globalInjection: true, // 全局注册$t方法
    messages,
});

export default i18n;
