
export default {
    path: "/payCallBack",
    name: "payCallBack",
    // meta: { title: '支付' },
    component: () => import("@/views/payCallBack"),
    children: [
        // 成功
        {
            path: "paySuc",
            name: "paySuc",
            component: () => import("@/views/payCallBack/paySuc"),
        },
       // 失败
        {
            path: "payFail",
            name: "payFail",
            component: () => import("@/views/payCallBack/payFail"),
        }
    ]
}

