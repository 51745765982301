var cityData =
    [
        {
            "code": "440100",
            "name": "廣州市",
            "cityType": 3,
            "isCapital": true,
            "show": true,
            "children": [
                {
                    "code": "440101",
                    "name": "市轄區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440103",
                    "name": "荔灣區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440104",
                    "name": "越秀區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440105",
                    "name": "海珠區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440106",
                    "name": "天河區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440111",
                    "name": "白雲區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440112",
                    "name": "黃埔區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440113",
                    "name": "番禺區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440114",
                    "name": "花都區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440115",
                    "name": "南沙區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440116",
                    "name": "蘿崗區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440183",
                    "name": "增城市",
                    "isCity": true,
                    "show": true
                },
                {
                    "code": "440184",
                    "name": "從化市",
                    "isCity": true,
                    "show": true
                }
            ]
        },
        {
            "code": "440300",
            "name": "深圳市",
            "cityType": 3,
            "isCapital": false,
            "show": true,
            "children": [
                {
                    "code": "440309",
                    "name": "前海深港現代服務業合作區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440301",
                    "name": "市轄區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440303",
                    "name": "羅湖區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440304",
                    "name": "福田區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440305",
                    "name": "南山區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440306",
                    "name": "寶安區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440307",
                    "name": "龍崗區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440308",
                    "name": "鹽田區",
                    "isCity": false,
                    "show": true
                }
            ]
        },
        {
            "code": "440400",
            "name": "珠海市",
            "cityType": 3,
            "isCapital": false,
            "show": true,
            "children": [
                {
                    "code": "440401",
                    "name": "市轄區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440402",
                    "name": "香洲區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440403",
                    "name": "鬥門區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440404",
                    "name": "金灣區",
                    "isCity": false,
                    "show": true
                }
            ]
        },
        {
            "code": "440600",
            "name": "佛山市",
            "cityType": 3,
            "isCapital": false,
            "show": true,
            "children": [
                {
                    "code": "440601",
                    "name": "市轄區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440604",
                    "name": "禪城區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440605",
                    "name": "南海區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440606",
                    "name": "順德區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440607",
                    "name": "三水區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "440608",
                    "name": "高明區",
                    "isCity": false,
                    "show": true
                }
            ]
        },
        {
            "code": "441300",
            "name": "惠州市",
            "cityType": 3,
            "isCapital": false,
            "show": true,
            "children": [
                {
                    "code": "441301",
                    "name": "市轄區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441302",
                    "name": "惠城區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441303",
                    "name": "惠陽區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441322",
                    "name": "博羅縣",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441323",
                    "name": "惠東縣",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441324",
                    "name": "龍門縣",
                    "isCity": false,
                    "show": true
                }
            ]
        },
        {
            "code": "441900",
            "name": "東莞市",
            "cityType": 3,
            "isCapital": false,
            "show": true,
            "children": [
                {
                    "code": "441901",
                    "name": "市轄區",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441902",
                    "name": "東城街道",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441903",
                    "name": "南城街道",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441904",
                    "name": "萬江街道",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441905",
                    "name": "莞城街道",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441906",
                    "name": "石碣鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441907",
                    "name": "石龍鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441908",
                    "name": "茶山鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441909",
                    "name": "石排鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441910",
                    "name": "企石鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441911",
                    "name": "橫沥鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441912",
                    "name": "橋頭鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441913",
                    "name": "謝崗鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441914",
                    "name": "東坑鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441915",
                    "name": "常平鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441916",
                    "name": "寮步鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441917",
                    "name": "樟木頭鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441918",
                    "name": "大朗鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441919",
                    "name": "黃江鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441920",
                    "name": "清溪鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441921",
                    "name": "塘廈鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441922",
                    "name": "風崗鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441923",
                    "name": "大嶺山鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441924",
                    "name": "長安鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441925",
                    "name": "虎門鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441926",
                    "name": "厚街鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441927",
                    "name": "沙田鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441928",
                    "name": "道滘鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441929",
                    "name": "洪梅鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441930",
                    "name": "麻涌鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441931",
                    "name": "望牛墩鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441932",
                    "name": "中堂鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441933",
                    "name": "高埗鎮",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441934",
                    "name": "松山湖",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441935",
                    "name": "東莞港",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441936",
                    "name": "東莞生態園",
                    "isCity": false,
                    "show": true
                },
                {
                    "code": "441937",
                    "name": "東莞濱海灣新區",
                    "isCity": false,
                    "show": true
                }
            ]
        },
    ]

module.exports = cityData
