<template>
  <el-dialog
    align-center
    :close-on-click-modal="false"
    v-bind="$attrs"
    v-model="dialogVisible"
    @closed="closed"
    class="dialogResize animate__animated animate__bounceInDown"
    :show-close="false"
  >
    <div class="content-box">
      <el-icon class="icon" color="#000" @click="dialogVisible = false">
        <CloseBold />
      </el-icon>
      <div class="content">
        <h5 class="text-center-center">
          關於公司名稱被盜用及冒用我司資訊的嚴正聲明
        </h5>
        <h5 class="text-center-center">2024 年 11 月 19 日</h5>
        <p class="mt-5">
          本公司（簡稱“灣區港人港企服務中心”或“灣區港人港企服務中心有限公司 Bay
          Area Hong Kong Enterprise Service Center Co., Limited”）
          近日發現有人冒用本公司名義從事商
          業行為，對此，本公司表示，現嚴正聲明：
        </p>
        <p class="mt-5">
          近期，灣區港人港企服務中心有限公司（簡稱“灣區港人港企服務中心”或“灣區港
          人港企服務中心有限公司 Bay Area Hong Kong Enterprise Service Center
          Co., Limited”或
          “我司”）收到諮詢，發現有人盜用我司名稱及冒用我司資訊，以灣區港人港企服務
          中心 Bay Area Hong Kong Enterprise Service Center Co., Limited
          名義發佈經營需求，對我司 合法權利構成嚴重侵害。
        </p>
        <p class="mt-5">
          上述情況，若經發現或對本公司造成任何經濟、聲譽損失，本公司將通過一切合法途
          徑追究法律責任。倘閣下接獲任何疑似上述活動的可疑通訊或訊息，應立即向警方舉
          報。敬請廣大市民與合作夥伴提高鑒別意識。如有任何查詢或疑問，請致電(+852)9334
          4571 與本公司聯絡。
        </p>
        <p class="mt-5">感謝您的關注與支持。</p>
        <p class="mt-5 text-center-right">灣區港人港企服務中心有限公司</p>
        <p class="text-center-right">
          Bay Area Hong Kong Enterprise Service Center Co., Limited
        </p>
        <p class="text-center-right">2024 年 11 月 19 日</p>
      </div>
    </div>
  </el-dialog>
</template>
<script setup>
import { defineProps, defineEmits, toRefs, ref } from "vue";

const emit = defineEmits(["update:modelValue"]);
import { CloseBold } from "@element-plus/icons-vue";

const props = defineProps({
  modelValue: Boolean,
});
const { modelValue } = toRefs(props);

let dialogVisible = ref(true);

const closed = () => {
  // emit("update:modelValue", false);
};
// const cutDown = (e) => {
//   emit("cutDown", e);
// };
</script>
<style>
@media screen and (min-width: 220px) and (max-width: 770px) {
  .el-dialog.dialogResize {
    width: 90% !important;
  }
}
</style>

<style lang="scss" scoped>
.image {
  border-radius: 20px;
  border: 5px solid #fff;
}

.content-box {
  position: relative;
  background: #fff;
  border-radius: 20px;

  .content {
    padding: 30px;
    color: #000;
    .text-center {
      &-center {
        text-align: center;
      }
      &-right {
        text-align: right;
      }
    }
  }
}

.icon {
  position: absolute;
  right: 2%;
  top: 2%;
  cursor: pointer;
  z-index: 2;
  font-size: 20px;
}
</style>
