
export default {
    path: "/news",
    name: "news",
    redirect: "/news/list",
    // meta: { title: '最新消息' },
    component: () => import("@/views/news"),
    children: [
        // 消息列表
        {
            path: "list",
            name: "newsList",
            component: () => import("@/views/news/list"),
        },
        // 消息详情
        {
            path: "detail/:id",
            name: "newsDetail",
            component: () => import("@/views/news/detail"),
        },
    ]
}

