
export default {
  path: "/mainlandCompany",
  name: "mainlandCompany",
  // meta: { title: '内地公司' },
  component: () => import("@/views/mainCompany"),
  children: [
    // 確認資料
    {
      name: "mainlandCompanyProfile",
      path: "mainlandCompanyProfile",
      component: () => import("@/views/mainCompany/profile"),
    },
    // 添加内地公司
    {
      name: "addMainlandCompany",
      path: "addMainlandCompany",
      component: () => import("@/views/mainCompany/registStep"),
    },
    // 内地公司列表
    {
      name: "mainlandCompanyList",
      path: "mainlandCompanyList",
      component: () => import("@/views/mainCompany/list"),
    },
    // 内地公司详情
    {
      name: "mainlandCompanyDetail",
      path: "mainlandCompanyDetail",
      component: () => import("@/views/mainCompany/detail"),
    },
  ],
};
