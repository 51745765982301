import request from '@/utils/request'

//共用同意使用条款 （除内地公司）
export function agreeTermsUse(orderId) {
    return request({
        url: `/serve/agreeTermsUse`,
        method: 'post',
        data: {orderId, step: 1}
    })
}

// 获取表单进度存储数据 （除内地公司，需要付费的服务）
export function getProgressTransactionDetails(orderId) {
    return request({
        url: `/serve/getProgressTransactionDetails?orderId=${orderId}`,
        method: 'get'
    })
}
/*---------------------------------------------------------*/

// 获取服务列表
export function getList(params) {
    return request({
        url: `/public/serve/getServeList`,
        method: 'get',
        params
    })
}

// 获取服务下拉列表
export function getServeDropDownList() {
    return request({
        url: `/public/serve/getServeDropDownList`,
        method: 'get'
    })
}

// 服务详情
export function getServeInfo(id) {
    return request({
        url: `/public/serve/getServiceDetailsById?id=${id}`,
        method: 'get'
    })
}

// 内地公司注册
export function mainlandCompanyRegister(step, data) {
    return request({
        url: `/serve/mainlandCompanyRegister${step}`,
        method: 'post',
        data
    })
}

// 同意使用条款
export function agreenRegist(data) {
    return request({
        url: `/serve/agreeToTermsOfUse`,
        method: 'post',
        data
    })
}


// 获取注册的步骤信息
export function getStepData(params) {
    return request({
        url: `/serve/mainlandCompanyRegisterData`,
        method: 'get',
        params
    })
}

// 获取当前服务订单id
export function getOrder(serveProjectId) {
    return request({
        url: `/order/getOrder`,
        method: 'post',
        data: {serveProjectId}
    })
}

// 获取内地公司服务订单列表
export function getServeOrderList(serveProjectId) {
    return request({
        url: `/serveOrder/getServeOrderByServeId?serveProjectId=${serveProjectId}`,
        method: 'get'
    })
}

// 内地公司注册放弃申請
export function MCRegisterAbandonApplication(orderId) {
    return request({
        url: `/serve/MCRegisterAbandonApplication/${orderId}`,
        method: 'put'
    })
}

// 获取内地公司进度列表详情
export function getMCProgressDetails(orderId) {
    return request({
        url: `/serve/getMCProgressDetails/${orderId}`,
        method: 'get'
    })
}

// 内地公司注册补填地址和房屋编码
export function mCRegisterComplete(data) {
    return request({
        url: `/serve/mCRegisterComplete`,
        method: 'put',
        data
    })
}


// 获取办理掛靠服务订单列表
export function getBlgkOrderList(serveProjectId) {
    return request({
        url: `/serve/getListProgressTransaction`,
        method: 'get'
    })
}

// 获取股东出资方式
export function getShareholderWaysOfInvestment(serveProjectId) {
    return request({
        url: `/company/getShareholderWaysOfInvestment`,
        method: 'get'
    })
}

// 获取内地公司服务订单列表
export function getASiteRentalProgressList() {
    return request({
        url: `/serve/getASiteRentalProgressList`,
        method: 'get'
    })
}


