<template>
  <div class="navTop">
    <div class="navContent container">
      <div class="container d-flex justify-content-between align-items-center">
        <div class="left">
          <div class="logo me-3 me-xl-4 me-xxl-5" @click="toPath('/')">
            <img src="../../assets/logo.png" alt="" />
          </div>
          <div class="tp-main-menu fw700">
            <ul>
              <li
                :class="{ active: currentPathName === 'home' }"
                @click="toPath('/')"
              >
                {{ $t("g.home") }}
              </li>
              <li
                :class="{ active: currentPathName === 'about' }"
                @click="toPath('/about')"
              >
                {{ $t("g.about") }}
              </li>
              <li
                :class="{ active: currentPathName === 'serviceList' }"
                @click="toPath('/serviceList')"
              >
                <el-dropdown popper-class="serviceListClass">
                  <div class="dropdown">
                    {{ $t("g.serve") }} <i class="iconfont icon-xiala"></i>
                  </div>
                  <template #dropdown>
                    <el-cascader-panel
                      ref="cascaderRef"
                      v-model="currentServeId"
                      :options="serviceList"
                      @change="serviceChange"
                    />
                  </template>
                </el-dropdown>
              </li>

              <li
                :class="{
                  active: ['newsList', 'newsDetail'].includes(currentPathName),
                }"
                @click="toPath('/news/list')"
              >
                {{ $t("g.news") }}
              </li>
              <li
                :class="{
                  active: [
                    'activityList',
                    'activityDetail',
                    'signUpList',
                  ].includes(currentPathName),
                }"
                @click="toPath('/activity/list')"
              >
                {{ $t("g.activity") }}
              </li>
              <li
                :class="{ active: currentPathName === 'commonProblem' }"
                @click="toPath('/commonProblem')"
              >
                {{ $t("g.problem") }}
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <div class="hasLogin" v-if="userInfo && userInfo.username">
            <div class="item">
              <!--              <el-dropdown trigger="click" class="dropdown">-->
              <!--                <div style="font-weight: 700;font-size: 17px;padding-top: 3px;color: #56575B;">EN<span>|</span>简</div>-->
              <!--                <template #dropdown>-->
              <!--                  <el-dropdown-menu>-->
              <!--                    <el-dropdown-item v-for="item in langList" @click="changeLang(item.value)">-->
              <!--                      {{ item.label }}-->
              <!--                    </el-dropdown-item>-->
              <!--                  </el-dropdown-menu>-->
              <!--                </template>-->
              <!--              </el-dropdown>-->
            </div>
            <div class="item" @click="toPath('/systemNews/list')">
              <!--       未读条数       -->
              <el-badge :value="unreadCount" :hidden="unreadCount == 0">
                <i class="iconfont icon-lingdang"></i>
              </el-badge>
            </div>
            <div class="item" @click="toPath('/shoppingCart')">
              <i class="iconfont icon-gouwuchekong"></i>
            </div>
            <div class="userBox">
              <el-dropdown
                trigger="click"
                ref="userDropRef"
                @visible-change="visibleChange"
              >
                <a class="dropdown-link" @click.prevent>
                  <div class="userIcon ms-2 me-2">
                    <el-avatar :src="userInfo.avatar" />
                  </div>
                  <i v-if="!dropdownIcon" class="iconfont icon-xiala"></i>
                  <el-icon v-else style="width: 16px; height: 16px">
                    <ArrowUp />
                  </el-icon>
                </a>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item
                      @click="toPath('/information/informationList')"
                      >{{ $t("top.myInfo") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click="toPath('/mainlandCompany/addMainlandCompany')"
                      >{{ $t("top.addMC") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click="toPath('/mainlandCompany/mainlandCompanyList')"
                      >{{ $t("top.mcList") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click="toPath('/hkCompany/addHkCompany')"
                      >{{ $t("top.addHKC") }}</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click="toPath('/hkCompany/hkCompanyList')"
                      >{{ $t("top.hkList") }}</el-dropdown-item
                    >
                    <!--                    <el-dropdown-item>{{ $t('top.supplierLink') }}</el-dropdown-item>-->
                    <el-dropdown-item @click="toPartner">{{
                      $t("top.partnerLogin")
                    }}</el-dropdown-item>
                    <!--                    <el-dropdown-item>供应商入口</el-dropdown-item>-->
                    <el-dropdown-item @click="toPath('/activity/signUpList')">{{
                      $t("top.signRecord")
                    }}</el-dropdown-item>
                    <el-dropdown-item divided @click="loginOut">{{
                      $t("top.logOut")
                    }}</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </div>
          <syButton v-else @click="toLogin" style="width: 166px">
            {{ $t("top.login") }}
            <i class="iconfont icon-fangxiang2-youbian"></i>
          </syButton>
        </div>
        <div class="rightNav">
          <a
            data-bs-toggle="offcanvas"
            href="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <i class="iconfont icon-caidan"></i>
          </a>
        </div>
      </div>

      <!--   侧边展开   -->
      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div class="offcanvas-header">
          <div class="logo">
            <img src="@/assets/images/logo2.jpg" alt="" />
          </div>
          <i
            class="iconfont icon-hexagon-xmark"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></i>
        </div>
        <div class="offcanvas-body">
          <el-scrollbar>
            <!--          <div class="dropdown">-->
            <!--            <button class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">語言切換</button>-->
            <!--            <ul class="dropdown-menu">-->
            <!--              <li v-for="item in langList" @click="changeLang(item.value)">-->
            <!--                <a class="dropdown-item" aria-label="Close" data-bs-dismiss="offcanvas" href="#">{{ item.label }}</a>-->
            <!--              </li>-->
            <!--            </ul>-->
            <!--          </div>-->
            <div class="accordion" id="accordionExample">
              <div
                class="item"
                aria-label="Close"
                data-bs-dismiss="offcanvas"
                @click="toPath('/')"
              >
                {{ $t("g.home") }}
              </div>
              <div
                class="item"
                aria-label="Close"
                data-bs-dismiss="offcanvas"
                @click="toPath('/about')"
              >
                {{ $t("g.about") }}
              </div>
              <div
                class="item"
                aria-label="Close"
                data-bs-dismiss="offcanvas"
                @click="toPath('/serviceList')"
              >
                服務列表
              </div>
              <div class="dropdown item">
                <button
                  class="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {{ $t("g.serve") }}
                </button>
                <ul class="dropdown-menu">
                  <el-tree :data="serviceList" @node-click="handleNodeClick" />
                </ul>
              </div>
              <div
                class="item"
                aria-label="Close"
                data-bs-dismiss="offcanvas"
                @click="toPath('/news/list')"
              >
                {{ $t("g.news") }}
              </div>
              <div
                class="item"
                aria-label="Close"
                data-bs-dismiss="offcanvas"
                @click="toPath('/activity/list')"
              >
                {{ $t("g.activity") }}
              </div>
              <div
                class="item"
                aria-label="Close"
                data-bs-dismiss="offcanvas"
                @click="toPath('/commonProblem')"
              >
                {{ $t("g.problem") }}
              </div>

              <template v-if="userInfo && userInfo.userId">
                <div
                  class="item"
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  @click="toPath('/systemNews/list')"
                >
                  <el-badge :value="unreadCount" :hidden="unreadCount == 0">
                    {{ $t("g.notice") }}
                  </el-badge>
                </div>
                <div
                  class="item"
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  @click="toPath('/information/informationList')"
                >
                  {{ $t("top.myInfo") }}
                </div>
                <div
                  class="item"
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  @click="toPath('/mainlandCompany/addMainlandCompany')"
                >
                  {{ $t("top.addMC") }}
                </div>
                <div
                  class="item"
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  @click="toPath('/mainlandCompany/mainlandCompanyList')"
                >
                  {{ $t("top.mcList") }}
                </div>
                <div
                  class="item"
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  @click="toPath('/hkCompany/addHkCompany')"
                >
                  {{ $t("top.addHKC") }}
                </div>
                <div
                  class="item"
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  @click="toPath('/hkCompany/hkCompanyList')"
                >
                  {{ $t("top.hkList") }}
                </div>
                <!--                <div class="item">开通供应商入口</div>-->
                <div
                  class="item"
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  @click="toPartner"
                >
                  {{ $t("top.partnerLogin") }}
                </div>
                <!--                <div class="item">供应商入口</div>-->
                <div
                  class="item"
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  @click="toPath('/activity/signUpList')"
                >
                  {{ $t("top.signRecord") }}
                </div>
                <div
                  class="item"
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  @click="toPath('/shoppingCart')"
                >
                  {{ $t("top.shoppingCart") }}
                </div>
                <div
                  class="item"
                  aria-label="Close"
                  data-bs-dismiss="offcanvas"
                  @click="loginOut"
                >
                  {{ $t("top.logOut") }}
                </div>
              </template>
              <div
                v-else
                class="item"
                aria-label="Close"
                data-bs-dismiss="offcanvas"
                @click="toPath('/login')"
              >
                {{ $t("top.login") }}
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import syButton from "../../components/syButton/index.vue";
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watchEffect,
  watch,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import bus from "@/utils/bus";
import { getServeDropDownList } from "@/api/serve";
import { useStore } from "vuex";

const store = useStore();
// 路由
const router = useRouter();
import { ElMessage } from "element-plus";
import { ArrowUp } from "@element-plus/icons-vue";
//引用i18n
import { useI18n } from "vue-i18n";

const { t, locale } = useI18n();

let userInfo = ref({});
userInfo.value = JSON.parse(sessionStorage.getItem("USER_INFO"));

//跳转合作商
const toPartner = () => {
  window.open("http://partner.gbaeasyhk.com/");
};

//下拉icon状态
const dropdownIcon = ref(false);

const visibleChange = (e) => {
  dropdownIcon.value = e;
};

// 定时器
const timer = ref(null);

// 获取当前用户信息
const serviceList = ref();

const handleNodeClick = (data) => {
  if (!data.children) {
    router.push(`/serveInfo?id=${data.value}`);
    document.getElementsByClassName("offcanvas")[0].classList.remove("show");
    document
      .getElementsByClassName("offcanvas-backdrop")[0]
      .classList.remove("show");
  }
};

// 商務配對路由列
const businessRouteList = [
  { id: 1, label: "商機搜尋", path: "", pathName: "" },
  { id: 2, label: "我的商務配對", path: "", pathName: "" },
  { id: 3, label: "我的需求", path: "", pathName: "" },
];

const serviceChange = (data) => {
  if (!data) return;
  let id = data.join();
  if (id) router.push(`/serveInfo?id=${id}`);
};

//监听头像是否更换
watchEffect(() => {
  if (store.state.userAvatar) {
    userInfo.value = JSON.parse(sessionStorage.getItem("USER_INFO"));
  }
});
onMounted(() => {
  getServeDropDownList().then((res) => {
    serviceList.value = res?.data || [];
  });

  // 重新获取session中个人信息数据
  bus.on("setUserInfo", (val) => {
    userInfo.value = JSON.parse(sessionStorage.getItem("USER_INFO")) || {};
  });

  // 重新获取未读条数
  bus.on("GetUnread", (val) => {
    getUnreadCount();
  });

  // 监听窗口大小变化
  // window.addEventListener('resize', function () {
  //   const userDropRef = ref('')
  //   // 获取窗口宽度
  //   const windowWidth = window.innerWidth;
  //   if (windowWidth <= 992) {
  //     // 设置侧边栏收起
  //     console.log(userDropRef)
  //     if(userDropRef.value){
  //
  //       userDropRef.value.handleClose()
  //     }
  //   }
  // });

  // 清除定时器
  bus.on("closeRead", (val) => {
    clearInterval(timer.value);
  });

  getUnreadCount();
  // 获取消息未读条数
  timer.value = setInterval(() => {
    try {
      getUnreadCount();
    } catch (e) {
      clearInterval(timer.value);
    }
  }, 10000);
});
//  页面销毁时清除定时器
onBeforeUnmount(() => {
  clearInterval(timer.value);
});

import { getInformUnreadCount } from "@/api/news";
// 获取消息未读条数
const unreadCount = ref(0);
const getUnreadCount = async () => {
  // 判断用户是否登录
  let token = sessionStorage.getItem("TOKEN");
  if (token) {
    let { data } = await getInformUnreadCount();
    unreadCount.value = data;
  }
};

// 监听路由
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
let currentServeId = ref([]); // 当前服务所在id
const currentPathName = ref("home");
const cascaderRef = ref();
watch(
  () => router.currentRoute.value,
  (route, oroute) => {
    currentPathName.value = route.name;
    // 获取当前服务id，设置服务下拉高亮值
    if (route.name === "serveInfo") {
      let currentId = route?.query?.id || null;
      if (currentId) {
        currentServeId.value = currentId.split(",").map(Number);
      }
    } else {
      // currentServeId.value = []
      cascaderRef?.value?.clearCheckedNodes();
    }

    // 动画计算刷新
    // gsap.registerPlugin(ScrollTrigger);
    // ScrollTrigger.refresh() //方法在适当的时机调用，例如在滚动事件中或者其他触发滚动重新计算的地方
  }
);

// 页面跳转
import { toTop, toForm, toPath } from "@/utils/global";

// 跳转登录页
const toLogin = () => {
  router.push("/login");
  toForm();
};

// 获取滚动高度
const getScrollHeight = () => {
  let scrollHeight = 0;
  scrollHeight =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop;
  const element = document.getElementsByClassName("navContent");
  if (scrollHeight >= 100) {
    element[0].classList.add("header-sticky");
  } else {
    element[0].classList.remove("header-sticky");
  }
};
window.addEventListener("scroll", getScrollHeight);

// 退出登录
import { logout } from "@/api/login";
import eventBus from "@/utils/bus";
const loginOut = async () => {
  try {
    let { messageCode } = await logout();
    ElMessage.success(t(`langCode.${messageCode}`));
  } catch (e) {}
  sessionStorage.removeItem("USER_INFO"); //删除本地登陆存储相关数据
  sessionStorage.removeItem("TOKEN"); //删除本地登陆存储相关数据
  sessionStorage.removeItem("REFRESH_TOKEN"); //删除本地登陆存储相关数据
  sessionStorage.removeItem("LOGIN_TIMESTAMP"); //删除本地登陆存储相关数据
  userInfo.value = {};
  // 给header通知: 可以获取本地存储信息了
  eventBus.emit("setUserInfo", true);
  await router.push("/login");
};
const currentLang = computed(() => locale.value);
const langList = ref([
  { label: "中文簡體", value: "zh" },
  { label: "中文繁體", value: "ft" },
  { label: "英文", value: "en" },
]);
const changeLang = (lang) => {
  locale.value = lang; // 切换语言
  localStorage.setItem("LANG", lang); // 本地存储当前语言類型
};

const skipServeDetails = (id) => {
  if (id) router.push("/serveInfo?id=" + id);
};
</script>

<style lang="scss">
.el-cascader-menu__wrap.el-scrollbar__wrap {
  height: auto !important;
}

.el-dropdown__popper.serviceListClass {
  margin-top: 20px !important;
}

.el-dropdown {
  .el-tooltip__trigger {
    outline: unset !important;
  }
}
</style>
<style scoped lang="scss">
::v-deep .el-dropdown__popper {
  margin-top: 20px !important;
}

::v-deep .el-tooltip__trigger {
  color: #8a90a2;
}

.navTop {
  width: 100%;
  position: absolute;
  left: 0;
  top: 40px;
  z-index: 999;

  .rightNav {
    display: none;
  }

  .navContent {
    .logo {
      cursor: pointer;
      width: 200px;
      //margin-right: 50px;
    }

    height: 120px;
    padding: 0 20px;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);

    .left,
    .right {
      display: flex;
      align-items: center;
    }

    .right {
      .hasLogin {
        display: flex;
        flex-direction: row;
        align-items: center;

        .userBox {
          display: flex;
          flex-direction: row;
          align-items: center;

          .userIcon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            overflow: hidden;
            background: #fff;
          }

          .dropdown-link {
            text-decoration: none !important;
            cursor: pointer;
            display: flex;
            align-items: center;
          }
        }

        .item {
          text-align: center;
          margin: 0 5px;
          cursor: pointer;

          .iconfont {
            font-size: 30px;
            color: #01374f;
          }
        }
      }
    }
  }

  .header-sticky {
    &.container,
    .container-lg,
    .container-md,
    .container-sm {
      max-width: 100% !important;
    }

    position: fixed;
    border-radius: 0;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    width: 100%;
    padding-top: 0;
    -webkit-animation: fadeInDown 0.3s ease-in-out 0s 1 normal none running;
    animation: fadeInDown 0.3s ease-in-out 0s 1 normal none running;
    box-shadow: 0 2px 55px rgba(168, 182, 218, 0.32);
    z-index: 99;
  }
}

/*右侧展开导航栏*/
.offcanvas-end {
  background: #01374f;
  color: #fff;
  font-size: 18px;
  padding: 20px;

  .offcanvas-header {
    .logo {
      width: 140px;
    }

    .icon-hexagon-xmark {
      font-size: 40px;
      transition: all 0.3s;
      color: #fff;

      &:hover {
        color: rgb(106, 205, 198);
        transform: rotate(10deg);
      }
    }
  }

  .offcanvas-body {
    .accordion {
      .item {
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          padding-left: 3px;
          color: rgb(106, 205, 198);
        }
      }
    }

    .dropdown {
      transition: all 0.3s;

      &:hover {
        padding-left: 3px;
        color: #2b4eff;
      }

      .dropdown-menu {
        width: 100%;
      }

      .dropdown-toggle {
        background: #01374f;
        border: none;
        height: 40px;
        color: #fff;
      }
    }
  }
}

.rightNav {
  display: block;

  .icon-caidan {
    font-size: 30px;
  }
}
@media only screen and (max-width: 1400px) {
  .logo {
    //margin-right: 30px !important;
  }
  .tp-main-menu ul li {
    font-size: 18px;
  }
  .el-dropdown {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 1200px) {
  .logo {
    width: 150px !important;
    //margin-right: 20px !important;
  }

  .tp-main-menu ul li {
    font-size: 16px;
    margin: 0 10px;

    .el-dropdown {
      height: 24px;
      line-height: 24px;
      font-size: 16px !important;
    }
  }
}

@media only screen and (max-width: 992px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 100%;
    border-radius: 0 !important;
    height: 100px !important;

    .logo {
      width: 140px !important;
    }
  }

  .navTop {
    top: 0;

    .tp-main-menu,
    .right {
      display: none !important;
    }

    .rightNav {
      display: block;
    }
  }
}
</style>
