<script setup>
import {computed, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {Back, Right} from '@element-plus/icons-vue'

const router = useRouter()
// component
import relatedService from '@/views/components/relatedService/index.vue'
import syButton from '@/components/syButton/index.vue'
import contactUs from '@/views/components/contactUs/index.vue'
import {toContactUs, toPath} from "@/utils/global";

// api
import {getHKCList, updateCompanyStatus} from '@/api/hkCompany'

import {ElMessage, ElMessageBox} from "element-plus";
//引用i18n
import {useI18n} from 'vue-i18n'
const {t} = useI18n()

//获取狀態信息
import {companyStatus} from "@/utils/enumData";

const companyStatusObj = computed(() => {
  return companyStatus.reduce((all, item) => {
    all[item.value] = item
    return all
  })
})
const tabs = ref([
  {name: t('serve.cName'), class: '', id: 1},
  {name: t('serve.cEmail'), class: 'd-none d-md-block', id: 2},
  {name: t('top.createDate'), class: 'd-none d-lg-block', id: 4},
  {name: t('serve.updateTime'), class: 'd-none d-lg-block', id: 5},
  {name: t('g.status'), class: '', id: 6},
  {name: t('g.action'), class: '', id: 7},
])

onMounted(() => {
  getProgressList()
})

// 获取进度列表（订单列表）
const listPage = ref({
  current: 1,
  size: 5,
})
const total = ref(0)
const progressList = ref([])
const getProgressList = async () => {
  let {data} = await getHKCList(listPage.value)
  progressList.value = data.list || []
  total.value = data.total
  listPage.value.current = data.current
  listPage.value.size = data.size
}
// 分页
const SizeChange = (pageSize) => {
  listPage.value.size = pageSize
  getProgressList()
};
const currentChange = (current) => {
  listPage.value.current = current
  getProgressList()
};

// 编辑
const toEdit = (item) => {
  let {step, orderId, companyId} = item
  if (step === '-1') {
    // 跳转须知页， 带上订单id
    router.push(`/mainland/agreen?orderId=${orderId}`)
  } else if (step > 0) {
    router.push(`/mainland/registStep/${orderId}/${companyId}`)
  }
}

</script>

<template>
  <!--   content   -->
  <div class="progressList container-fluid">
    <!--  大屏列表  -->
    <div>
      <div class="serveIntro mb-4 mb-md-5">
        <div class="introBox row d-flex align-items-center">
          <div class="item h6 col" :class="item.class" v-for="item in tabs" :key="item.id">{{ item.name }}</div>
        </div>
      </div>

      <!--   空数据   -->
      <el-empty :description="$t('g.none')" v-if="progressList && progressList.length === 0"/>

      <!--   列表数据   -->
      <div class="green-bd col row align-items-center text-center pt-3 pb-3 mb-4" v-for="item in progressList"
           :key="item.orderId">
        <div class="col" :class="tabs[0].class">{{ item.hkcName || $t('g.none') }}</div>
        <div class="col" :class="tabs[1].class">{{ item.hkcEmail }}</div>
        <div class="col" :class="tabs[2].class">{{ item.createdAt }}</div>
        <div class="col" :class="tabs[3].class">{{ item.updatedAt }}</div>
        <div class="col" :class="tabs[4].class">{{
            companyStatusObj[item.status] ? companyStatusObj[item.status].label :
                ''
          }}
        </div>
        <div class="actionBox col" :class="tabs[5].class">
          <div class="d-flex flex-wrap flex-column">
            <syButton class="fs-6" @click="toPath('/hkCompany/hkCompanyDetail?id=' + item.hkcId)">
              {{ $t('g.view') }}<i class="iconfont icon-fangxiang2-youbian"></i></syButton>
            <!-- -->
            <syButton v-if="item.status == '2'" base-color="#106A88" font-color="#fff" class="fs-6 ms-0 mt-2"
                      @click="toPath('/hkCompany/addHkCompany?id=' + item.hkcId)">
              {{ $t('g.edit') }}<i class="iconfont icon-fangxiang2-youbian"></i>
            </syButton>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--   分页   -->
  <el-pagination v-if="progressList && progressList.length > 0"
                 v-model:current-page="listPage.current"
                 class="d-flex justify-content-center mb-5"
                 :prev-icon="Back"
                 :next-icon="Right"
                 :page-size="listPage.size"
                 @size-change="SizeChange"
                 @current-change="currentChange"
                 layout="prev, pager, next"
                 :total="total"/>

  <!-- 相关服务 -->
  <relatedService class="mb-5" current-id="6"></relatedService>

  <!--   联系我们   -->
  <contactUs></contactUs>
</template>

<style scoped lang="scss">
.sy-button {
  height: 50px;
  padding: 0 10px;

}

.green-bd {
  border: 1px solid rgba(106, 205, 198, 0.40);
  border-radius: 20px;
}
</style>
