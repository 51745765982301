
export default {
    path: "/activity",
    name: "activity",
    redirect: "/activity/applyRecord",
    component: () => import("@/views/activity"),
    children: [
        // 活动列表
        {
            path: "list",
            name: "activityList",
            component: () => import("@/views/activity/activityList"),
            // meta: { title: '活動動態' }
        },
        // 活动详情
        {
            path: "detail/:id",
            name: "activityDetail",
            component: () => import("@/views/activity/activityDetail"),
            // meta: { title: '活動動態' }
        },
        // 活动报名记录
        {
            path: "signUpList",
            name: "signUpList",
            component: () => import("@/views/activity/signUpRecord"),
            // meta: { title: '活動報名記錄' }
        },
    ]
}

