<script setup>
import syButton from '@/components/syButton/index.vue'
import {onMounted, ref} from "vue";

let acceptCookie = ref('1')

onMounted(() => {
  acceptCookie.value = window.localStorage.getItem('acceptCookie') || ''
})

const handleAccept = () => {
  acceptCookie.value = '1'
  window.localStorage.setItem('acceptCookie', '1')
}
</script>

<template>
<div class="acceptCookie" :class="{'d-none': acceptCookie === '1'}">
  <div class="container">
    <div class="container-fluid bg-white p-4">
      <h5 class="fw700">Cookie偏好</h5>
      <p>您好！我們重視您的隱私，並希望確保您在我們的網站上獲得最佳的瀏覽體驗。與大多數網站一樣，我們使用Cookie來個性化內容和廣告、提供社交媒體功能以及分析流量。請點擊下方的「接受」按鈕，即表示您同意在我們的網站上使用Cookie和其他追蹤技術。您可以隨時改變主意並修改Cookie偏好，方法是訪問我們的Cookie政策。感謝您的理解，並祝您在我們的網站上愉快探索！</p>
      <div class="d-flex justify-content-end">
        <syButton @click="handleAccept">接受</syButton>
      </div>
    </div>
    </div>
</div>
</template>

<style scoped lang="scss">
.acceptCookie{
  position: fixed;
  bottom: 40px;
  width: 100%;
  z-index: 100;
  .bg-white{
    border-radius: 20px;
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.1);
  }
}
</style>
