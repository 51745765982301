import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 动画
import 'animate.css';

// bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

//
import './assets/style/global.scss'
import './assets/style/iconfont.css'

// 国际化
import i18n from './lang'

// element-plus UI
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/style/element/index.scss'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

// 禁止手指缩放
// 设置 viewport 的 meta 標簽，禁止双击放大
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const viewportMeta = document.querySelector('meta[name="viewport"]');

if (isMobile && viewportMeta) {
    viewportMeta.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0');
}

//vue-gtag串接google analytics
import VueGtag from "vue-gtag";

import MetaInfo from 'vue-meta-info'

import GoogleSignInPlugin from "vue3-google-signin"
createApp(App)
    .use(router)
    .use(store)
    .use(i18n)
    .use(ElementPlus)
    .use(MetaInfo)
    .use(ElementPlus, {locale: zhCn,})
    .use(VueGtag, {config: { id: "G-TRJS8179D0" }})
    .use(GoogleSignInPlugin, {
        clientId: '114492311147-tpjvtomubphh91145d89velthmisso3u.apps.googleusercontent.com'
    })
    .mount("#app");


