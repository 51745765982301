
export default {
    path: "/xgpBud",
    name: "xgpBud",
    redirect: "/xgpBud/progressList",
    // meta: { title: '香港區BUD' },
    component: () => import("@/views/serve/xgpBud"),
    children: [
        // 同意须知
        {
            path: "agreen",
            name: "xgpBudAgreen",
            component: () => import("@/views/serve/xgpBud/agreen"),
            // meta: { title: '服務協議' }
        },
        // 注册步骤
        {
            path: "progressForm",
            name: "xgpBudProgressForm",
            component: () => import("@/views/serve/xgpBud/progressForm"),
        },
        // 进度列表
        {
            path: "progressList",
            name: "xgpBudProgressList",
            component: () => import("@/views/serve/xgpBud/progressList"),
        },
        // 详情
        {
            path: "detail",
            name: "xgpBudDetail",
            component: () => import("@/views/serve/xgpBud/progressList/detail"),
        },
    ]
}

