<script setup>
import { computed, onMounted, ref } from 'vue'
import { useRouter } from "vue-router";
//引用i18n
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const router = useRouter()
// component
import relatedService from '@/views/components/relatedService/index.vue'
import syButton from '@/components/syButton/index.vue'
import syUpload from '@/components/SY_upload/index.vue'

import syPicView from "@/components/SY_PicView/index.vue";
import { toContactUs, toPath } from "@/utils/global";

// api
import { getHKCDetails } from '@/api/hkCompany'

import { goBack } from "@/utils/global";
import { statusList } from "@/utils/enumData";
import { ElMessage, ElMessageBox } from "element-plus";

const formData = ref({})
const id = ref('')

onMounted(() => {
  id.value = router.currentRoute.value.query.id
  getDetailData()
})

//获取狀態信息
import { companyStatus } from "@/utils/enumData";
const companyStatusObj = computed(() => {
  return companyStatus.reduce((all, item) => {
    all[item.value] = item
    return all
  })
})

// 获取详情数据
const getDetailData = async () => {
  console.log(getHKCDetails)
  let { data } = await getHKCDetails(id.value)
  formData.value = data
}

// 步骤
const tabs = ref([
  { name: t('serve.confirmCName'), step: '1' },
  { name: t('serve.rCertificate'), step: '2' },
  { name: t('serve.partialNail'), step: '3' },
  { name: t('serve.partB'), step: '4' },
  { name: t('serve.partC'), step: '5' },
  { name: t('serve.partD'), step: '6' },
])
const step = ref('1')

</script>

<template>
  <!--   content   -->
  <div class="detailBox p-4 p-md-5 card">
    <h2 class="fw700">{{ $t('serve.hkcDetail') }}</h2>
    <div class="info mt-3 mb-3 fw700 lh-lg">
      <div class="row">
        <div class="col col-md-3 bold700">{{ $t('g.status') }}</div>
        <div class="col col-md-9">
          {{ companyStatusObj[formData.status] ? companyStatusObj[formData.status].label : '' }}
        </div>
        <div class="row d-flex align-items-center">
          <div class="col col-md-3 bold700">{{ $t('serve.cName') }}</div>
          <div class="col col-md-9">{{ formData.hkcName }}</div>
        </div>
        <div class="row d-flex align-items-center">
          <div class="col col-md-3 bold700">{{ $t('serve.cEmail') }}</div>
          <div class="col col-md-9">{{ formData.hkcEmail }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-3 bold700">BR{{ $t('serve.expirationTime') }}</div>
        <div class="col col-md-9">{{ formData.brExpirationTime }}</div>
      </div>
      <div class="row">
        <div class="col col-md-3 bold700">BR{{ $t('serve.number') }}</div>
        <div class="col col-md-9">{{ formData.brNumber }}</div>
      </div>
      <div class="row">
        <div class="col col-md-3 bold700">BR{{ $t('serve.file') }}</div>
        <div class="col col-md-9">
          <syPicView :pic-url="formData.brFile"></syPicView>
        </div>
      </div>
      <div class="row">
        <div class="col col-md-3 bold700">Cl{{ $t('serve.file') }}</div>
        <div class="col col-md-9">
          <syPicView :pic-url="formData.ciFile"></syPicView>
        </div>
      </div>
      <div class="row" v-if="formData.status == 2">
        <div class="col col-md-3 bold700">{{ $t('serve.rejectInfo') }}</div>
        <div class="col col-md-9">{{ formData.dismissMessage }}</div>
      </div>
    </div>
    <div class="mt-5 d-flex">
      <sy-button @click="goBack">{{ $t('g.back') }}</sy-button>
      <syButton v-if="formData.status == '2'" base-color="#106A88" font-color="#fff"
        @click="toPath('/hkCompany/addHkCompany?id=' + formData.hkcId)">
        {{ $t('g.edit') }}<i class="iconfont icon-fangxiang2-youbian"></i>
      </syButton>
    </div>
  </div>

  <!--  相关服务  -->
  <relatedService class="mb-5" current-id="6"></relatedService>
</template>

<style scoped lang="scss">
.row {
  margin-top: 10px;
}

.lh45 {
  line-height: 45px;
}

.card {
  border: 1px solid #6ACDC6;
}

.detailBox {
  border: 1px solid rgba(106, 205, 198, 0.40);
}

</style>
