export default [
    // 微信公众号开通
    {
        path: "/gzhkt",
        name: "gzhkt",
        redirect: "/gzhkt/progressList",
        component: () => import("@/views/serve/pptg/gzhkt"),
        children: [
            // 同意须知
            {
                path: "agreen",
                name: "gzhktAgreen",
                component: () => import("@/views/serve/pptg/gzhkt/agreen"),
                // meta: { title: '服務協議' }
            },
            // 注册步骤
            {
                path: "progressForm",
                name: "gzhktProgressForm",
                component: () => import("@/views/serve/pptg/gzhkt/progressForm"),
            },
            // 进度列表
            {
                path: "progressList",
                name: "gzhktProgressList",
                component: () => import("@/views/serve/pptg/gzhkt/progressList"),
            },
            // 详情
            {
                path: "detail",
                name: "gzhktDetail",
                component: () => import("@/views/serve/pptg/gzhkt/detail"),
            },
        ]
    },

    // 微信公众号营运

]

