
export default {
    path: "/jzbs",
    name: "jzbs",
    redirect: "/jzbs/progressList",
    // meta: { title: '記帳報稅' },
    component: () => import("@/views/serve/jzbs"),
    children: [
        // 同意须知
        {
            path: "agreen",
            name: "jzbsAgreen",
            component: () => import("@/views/serve/jzbs/agreen"),
            // meta: { title: '服務協議' }
        },
        // 注册步骤
        {
            path: "progressForm",
            name: "progressForm",
            component: () => import("@/views/serve/jzbs/progressForm"),
        },
        // 上傳公账
        {
            path: "uploadPublicAccount",
            name: "uploadPublicAccount",
            component: () => import("@/views/serve/jzbs/uploadPublicAccount"),
        },
        // 进度列表
        {
            path: "progressList",
            name: "jzbsProgressList",
            component: () => import("@/views/serve/jzbs/progressList"),
        },
        // 详情
        {
            path: "detail",
            name: "jzbsDetail",
            component: () => import("@/views/serve/jzbs/progressList/detail"),
        },
    ]
}

